<template>
  <div class="card-content detail-content body-payment">
    購入ありがとうございました
  </div>
</template>

<script>
// import { StripeElementCard } from "@vue-stripe/vue-stripe";

export default {
  name: "PageThank",
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style lang="scss">
.body-payment {
  min-height: auto !important;
  margin: 0 auto;
  width: 853.6px;
  padding-left: 15px;
  padding-top: 20px;
  @media (max-width: 992px) {
    width: 720px;
  }
  @media (max-width: 767px) {
    width: 540px;
  }
  @media (max-width: 575px) {
    width: 100%;
  }
}
</style>
